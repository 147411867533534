import React from 'react';

const Projects = () => {
  return (
    <section id="projects" className="section-parent">
      <div className="section-content">
        <h2>Projects</h2>
        <p>Section Placeholder Text</p>
      </div>
    </section>
  );
};

export default Projects;
