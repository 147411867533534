import React from 'react';

const Contact = () => {
  return (
    <section id="contact" className="section-parent">
      <div className="section-content">
        <h2>Contact</h2>
        <p>Section Placeholder Text</p>
      </div>
    </section>
  );
};

export default Contact;
