import React, { useEffect, useState } from 'react';
import { Menu, X } from 'lucide-react';
import Navigation from './Navigation';
import './Header.css';

const Header = () => {
  const ANIMATION_DURATION = 300;

  const [menuOpen, setMenuOpen] = useState(false);
  const [animating, setAnimating] = useState(false);
  const toggleMenu = () => {
    if (animating) return; // prevent toggle spam

    setAnimating(true);
    setMenuOpen(!menuOpen);

    setTimeout(() => {
      setAnimating(false);
    }, ANIMATION_DURATION);
  };
  const closeMenu = () => setMenuOpen(false);

  useEffect(() => {
    document.body.classList.toggle('no-scroll', menuOpen);
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [menuOpen]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 769px)');
    const handleResize = () => {
      if (mediaQuery.matches) {
        setMenuOpen(false);
        document.body.classList.remove('no-scroll');
      }
    };
    handleResize();
    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  return (
    <header className="header">
      <nav className="desktop-nav">
        <Navigation />
      </nav>
      <button
        className="menu-toggle"
        onClick={toggleMenu}
        style={{ visibility: animating ? 'hidden' : 'visible' }}
      >
        {menuOpen ? <X size={32} /> : <Menu size={32} />}
      </button>
      <nav className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
        <Navigation onClick={closeMenu} />
      </nav>
    </header>
  );
};

export default Header;
