import React from 'react';

const About = () => {
  return (
    <section id="about" className="section-parent">
      <div className="section-content">
        <h2>About</h2>
        <p>Section Placeholder Text</p>
      </div>
    </section>
  );
};

export default About;
