import React from 'react';

const Education = () => {
  return (
    <section id="education" className="section-parent">
      <div className="section-content">
        <h2>Education</h2>
        <p>Section Placeholder Text</p>
      </div>
    </section>
  );
};

export default Education;
