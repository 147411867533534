import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Home } from './components/Home';
import './index.css';

const rootElement = document.getElementById('root') as HTMLElement;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Router>
        <Home />
      </Router>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found in the DOM.');
}
