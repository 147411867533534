import React from 'react';

const Navigation = ({ onClick }: { onClick?: () => void }) => (
  <>
    <a href={'#about'} onClick={onClick}>
      Who's This Guy?
    </a>
    <a href={'#skills'} onClick={onClick}>
      Superpowers
    </a>
    <a href={'#experience'} onClick={onClick}>
      Where I've Been
    </a>
    <a href={'#projects'} onClick={onClick}>
      Stuff I Built
    </a>
    <a href={'#education'} onClick={onClick}>
      What I've Learned
    </a>
    <a href={'#achievements'} onClick={onClick}>
      Wall of Achievements
    </a>
    <a href={'#contact'} onClick={onClick}>
      Let's Connect!
    </a>
  </>
);

export default Navigation;
