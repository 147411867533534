import React from 'react';

const Skills = () => {
  return (
    <section id="skills" className="section-parent">
      <div className="section-content">
        <h2>Skills</h2>
        <p>Section Placeholder Text</p>
      </div>
    </section>
  );
};

export default Skills;
