import React from 'react';
import { Header } from '../Header';
import { Hero } from '../Hero';
import { About } from '../About';
import { Skills } from '../Skills';
import { Experience } from '../Experience';
import { Education } from '../Education';
import { Projects } from '../Projects';
import { Achievements } from '../Achievements';
import { Contact } from '../Contact';
import { Footer } from '../Footer';
import './Home.scss';

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Skills />
      <Experience />
      <Projects />
      <Education />
      <Achievements />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
